.item-page {
  @media screen and (max-width: 800px) {
    overflow-x: hidden;
  }

  &__bg {
    min-height: 100%;
    min-width: 1024px;
    filter: blur(1.3px) brightness(60%);
    width: 100%;
    height: auto;
    position: fixed;
    top: 0;
    left: 0;

    @media screen and (max-width: 800px) {
      left: 50%;
      margin-left: -512px;
    }
  }

  .item {
    width: 100%;
    display: flex;
    justify-content: center;

  &__outer {
    background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8));
    margin-top: 85px;
    height: 530px;
    width: 1000px;
    position: absolute;
    top: 0;

    @media screen and (max-width: 800px) {
      margin-top: 150px;
      margin-bottom: 20px;
      width: 100%;
      height: 1000px;
      background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
    }
  }

  
  &__inner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media screen and (max-width: 800px) {
      flex-direction: column;
    }
  }

  &__img-box {
    @media screen and (max-width: 800px) {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  &__poster-img {
    width: 365px;
    height: 530px;
    filter: brightness(105%);
    filter: contrast(110%);

    @media screen and (max-width: 800px) {
      width: 265px;
      height: 430px;
      margin: 10px auto;
    }
  }


  &__text-box {
    color: white;
    font-size: 15px;
    margin-left: 15px;
    margin-right: 22px;

    font-weight: lighter;
    padding: 5px;
    font-family: "Kanit",sans-serif;
    text-shadow: 3px 3px 4px black;

    @media screen and (max-width: 800px) {
      margin: unset;
      margin: 0 auto;
      width: 90%;
      text-align: center;
      padding-bottom: 15px;
      font-weight: bolder;
    }
  }

  &__title {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 23px;
  }

  &__overview {
    font-size: 0.9rem;
    font-weight: lighter;
    line-height: 21px;
    margin-top: 0px;
    display: block;
  }

  .item-rating {
    padding: 5px;
    background-color: black;
    display: inline-flex;
    align-items: center;
    margin-top: 15px;
    border-radius: 5px;

    @media screen and (max-width: 800px) {
      margin-top: 15px;
    }

    &__rank {
      font-size: 1.1rem;
      font-weight: lighter;
      margin-left: 7px;
      margin-top: 3px;
    }
  
    &__ten {
      font-size: 14px;
      margin-top: 4px;
    }
  
    &__imdb {
      width: 50px;
    }
  
    &__star {
      width: 30px;
    }
  }



  &__cast-title {
    font-size: 1.2rem;
    text-decoration: underline;

    @media screen and (max-width: 800px) {
      padding-top: 10px;
    }
  }
}
}
