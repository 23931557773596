.signin {
  @media screen and (max-width: 800px) {
    overflow-x: hidden;
  }

  &__bg {
    min-height: 100%;
    min-width: 1024px;
    background-repeat: no-repeat;
    background-position: center;
    filter: brightness(65%);
    width: 100%;
    height: auto;
    position: fixed;
    top: 0;
    left: 0;

    @media screen and (max-width: 800px) {
      left: 50%;
      margin-left: -512px;
    }
  }

  &__container {
    width: 100%;
    display: flex;
    justify-content: center;

    @media screen and (max-width: 800px) {
      width: 100%;
    }
  }

  &__shadow {
    background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8));
    margin-top: 85px;
    height: 540px;
    width: 390px;
    position: absolute;
    top: 0;

    @media screen and (max-width: 800px) {
      margin-top: 150px;
      width: 330px;
      margin-left: auto;
      margin-right: auto;
      height: 460px;
      margin-bottom: 20px;
    }
  }

  &__title {
    margin-left: 60px;
    margin-top: 25px;
    margin-bottom: 25px;
   
  }

  &__btn-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &__btn {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 15px;
    text-align: center;
    width: 70%;
  }

  &__google-icon {
    font-size: 1.2rem;
    height: 100%;
    display: flex;
    align-items: center;
    margin-right: 10px;
    margin-bottom: 10px;
  }

  &__option {
    width: 100%;
    display: flex;
    justify-content: center;

    &--newuser {
      color: #737373;
      text-align: center;
      margin-right: 5px;
    }

    &--link {
      color: white;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
