.header {
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 40;

  @media screen and (max-width: 800px) {
    flex-direction: column;
    height: 120px;
  }

  &__logo-box {
    @media screen and (max-width: 800px) {
      margin: 0 auto;
    }
  }

  &__logo {
    height: 100%;
    width: 120px;
    margin-left: 20px;
    cursor: pointer;
    margin-bottom: 10px;

    @media screen and (max-width: 800px) {
      margin: unset;
    }
  }

  &__options-primary,
  &__options-secondary {
    @media screen and (max-width: 800px) {
      display: none;
    }
  }

  &__options {
    width: 88%;
    height: 100%;
    display: flex;
    align-items: center;

    @media screen and (max-width: 800px) {
      text-align: center;
      width: 100%;
      display: flex;
      flex-direction: row;
      margin-bottom: 25px;
      height: 200px;
      justify-content: center;
    }
  }

  &__option {
    padding: 10px 12px;
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 550;
    background-color: transparent;
    cursor: pointer;
    color: #e5e5e5;
    text-decoration: none;
    line-height: 1.2;
    -webkit-font-smoothing: antialiased;

    @media screen and (max-width: 800px) {
      padding: 5px 6px;
      font-size: 13px;
    }

    &:hover {
      color: #a9a9a9;
      transition: 0.4s all;
    }

    &--signin {
      margin-right: 25px;
      @media screen and (max-width: 800px) {
        margin: unset;
      }
    }

    &--signout {
      display: inline;
      margin-right: 15px;
      @media screen and (max-width: 800px) {
        margin: unset;
      }
    }
  }

  &__searchbar {
    margin-left: auto;
    margin-right: 15px;

    @media screen and (max-width: 800px) {
      margin: unset;
      position: absolute;
      top: 65%;
      margin: 0 auto;
    }
  }

  &__nav-menu-icon {
    @media screen and (min-width: 800px) {
      display: none;
    }
    @media screen and (max-width: 800px) {
      display: inline-block;
      font-size: 25px;
      color: white;
      position: absolute;
      top: 5%;
      left: 2%;
      padding: 10px;
      transition: all 0.3s ease;
      cursor: pointer;

      &:active {
        transform: scale(1.1);
      }
    }
  }
}

.cssbuttons-io-button {
  background: #A370F0;
  color: white;
  font-family: inherit;
  padding: 0.35em;
  padding-left: 1.2em;
  font-size: 17px;
  font-weight: 500;
  border-radius: 0.9em;
  border: none;
  letter-spacing: 0.05em;
  display: flex;
  align-items: center;
  box-shadow: inset 0 0 1.6em -0.6em #714da6;
  overflow: hidden;
  position: relative;
  height: 2.8em;
  padding-right: 3.3em;
 }
 
 .cssbuttons-io-button .icon {
  background: white;
  margin-left: 1em;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.2em;
  width: 2.2em;
  border-radius: 0.7em;
  box-shadow: 0.1em 0.1em 0.6em 0.2em #7b52b9;
  right: 0.3em;
  transition: all 0.3s;
 }
 
 .cssbuttons-io-button:hover .icon {
  width: calc(100% - 0.6em);
 }
 
 .cssbuttons-io-button .icon svg {
  width: 1.1em;
  transition: transform 0.3s;
  color: #7b52b9;
 }
 
 .cssbuttons-io-button:hover .icon svg {
  transform: translateX(0.1em);
 }
 
 .cssbuttons-io-button:active .icon {
  transform: scale(0.95);
 }
 